import { Component, Signal, WritableSignal, effect, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ComponentType } from '@angular/cdk/portal';
import { Observable, map } from 'rxjs';

import { TransactionBaseComponent } from '../transaction-base/transaction-base.component';

import { InformationPlanetService } from '../../services/information-planet.service';
import { InformationPopulationService } from '../../services/information-population.service';
import { TableService } from '../../services/table.service';

import { Government } from '../../interfaces/government';
import { InformationPlanet } from '../../interfaces/information-planets';
import { InformationPopulation } from '../../interfaces/information-population';
import { Transaction } from '../../interfaces/transaction';

@Component({
  template: '<div></div>',
})
export class TroopsBaseComponent extends TransactionBaseComponent {
  informationPlanetService = inject(InformationPlanetService);
  informationPopulationService = inject(InformationPopulationService);
  tableService = inject(TableService);

  pageTitle: string = "Planetary Control Forces (PCF)";
  readOnly: boolean = false;
  turnStartUp: boolean = false;

  planetInfo: Signal<InformationPlanet[]>;
  populationInfo: Signal<InformationPopulation[]>;
  starSystemsPopulationInfo: { [key: string]: InformationPopulation[]; } = {};
  systemsSet: WritableSignal<string[]> = signal([]);

  bodyData: WritableSignal<any[]> = signal([]);

  puTotal: number;
  pcfMax: number;
  populationLookupHash: { [key: string]: any; } = {};
  transactionLookupHash: { [key: string]: any; } = {};
  planetHash: { [key: string]: any; } = {};
  government: Signal<Government>;

  componentType: ComponentType<unknown> | undefined = undefined;

  private setTransactionHash (transaction: Transaction) {
    let bodyId = transaction.bodyId;
    let baseObject = this.transactionLookupHash[bodyId] || {};
    let pcfType = transaction.description;
    baseObject[pcfType + 'id'] = transaction._id;
    baseObject[pcfType + 'Quantity'] = transaction.quantity;
    baseObject[pcfType + 'Amount'] = transaction.amount;
    this.transactionLookupHash[transaction.bodyId] = baseObject;
  };

  private clearTransactionHash (transaction: Transaction) {
    let bodyId = transaction.bodyId;
    let pcfType = transaction.description;
    let baseObject = this.transactionLookupHash[bodyId] || {};
    baseObject[pcfType + 'id'] = undefined;
    baseObject[pcfType + 'Quantity'] = undefined;
    baseObject[pcfType + 'Amount'] = undefined;
    this.transactionLookupHash[bodyId] = baseObject;
  };

  constructor () {
    super();

    this.transactionType = "troops";
    this.transactionLookupHash = {};
    this.turnStartUp = this.session.turnEdit() === this.race().turnActivated;

    this.updatedTransaction.subscribe(updatedTransaction => {
      this.setTransactionHash(updatedTransaction);
    });

    this.deletedTransaction.subscribe(deletedTransaction => {
      this.clearTransactionHash(deletedTransaction);
    });

    this.government = toSignal(this.tableService.getGovernmentById(
      this.race().governmentId as number,
      this.race().campaignId as string
    ));

    this.planetInfo = toSignal(
      this.informationPlanetService.getInformationPlanetsForRaceAndStarSystemIds(this.race()._id, this.race().starSystemId)
    );

    let getPopulationInfo$: Observable<InformationPopulation[]>;
    if (this.turnStartUp) {
      getPopulationInfo$ = this.informationPopulationService.getInformationPopulationForRaceIdAndStarSystemId(this.race()._id, this.race().starSystemId);
    }
    else {
      getPopulationInfo$ = this.informationPopulationService.getInformationPopulationForRaceId(this.race()._id);
    }

    getPopulationInfo$ = getPopulationInfo$.pipe(
      map(informationPopulations => {
        let systemsSet = new Set<string>();
        let filteredInformationPopulations = informationPopulations.filter(informationPopulation => {
          let hasPopulation = informationPopulation.ptu > 0;
          if (hasPopulation) {
            systemsSet.add(informationPopulation.starSystemId);
            let informationPopulation2: InformationPopulation = informationPopulation;
            informationPopulation2.maxIu = Math.floor(informationPopulation2.pu / 2);
            this.starSystemsPopulationInfo[informationPopulation.starSystemId] = (this.starSystemsPopulationInfo[informationPopulation.starSystemId] || []).concat(informationPopulation2);
          }
          return hasPopulation;
        });
        this.systemsSet.set(Array.from(systemsSet));
        return filteredInformationPopulations;
      })
    );
    this.populationInfo = toSignal(getPopulationInfo$);

    this.puTotal = 0;

    effect(() => {
      if (!this.populationInfo() || !this.transactions() || !this.planetInfo() || !this.government()) {
        return;
      }

      this.session.iAmBusy();

      // initialize variables
      let techLevel = this.race().techLevel;
      let pcfMultiplier = this.government().pcf / 100 * (techLevel >= 1 ? 0.75 : 1);
      let pcfaMultiplier = this.government().pcf / 100 * (techLevel >= 1 ? 0.25 : 0);
      this.transactionLookupHash = {};
      this.planetHash = this.planetInfo().reduce((newHash, informationPlanet: InformationPlanet) => {
        newHash[informationPlanet.bodyId] = informationPlanet;
        return newHash;
      }, {});

      // Step 1
      this.populationLookupHash = this.populationInfo().reduce((newHash, lookupData: any) => {
        let transactionLookup = this.transactionLookupHash[lookupData.planetId] || {};
        let hd = lookupData.hd as number;
        // this is not set correctly - yet:
        let maxPu = (hd <= 2) ? 3200 :
          (hd < 10) ? 800 :
            (hd <= 10) ? 150 :
              (hd <= 12) ? 50 :
                (hd <= 18) ? 16 : 0;
        if (lookupData.locator.indexOf('(A)') > -1) {
          transactionLookup['showAnyway'] = true;
          let informationPlanet = this.planetHash[lookupData.planetId];
          if (informationPlanet && informationPlanet.radius) {
            let hexes = Math.ceil(informationPlanet.radius / 12) * 6;
            // population centers allowed ===  5 per hex === 5 * hexes
            let popCenters = 5 * hexes;
            // max ptu per <population center> === 16 ptu (outpost)
            maxPu = popCenters * 16;
          }
        }

        transactionLookup['freePcf'] = Math.round(lookupData.pu * pcfMultiplier);
        transactionLookup['freePcfa'] = Math.round(lookupData.pu * pcfaMultiplier);
        // override until fixed
        transactionLookup.maxPu = maxPu;
        newHash[lookupData.planetId] = lookupData;
        this.puTotal = this.puTotal + lookupData.pu;
        this.transactionLookupHash[lookupData.planetId] = transactionLookup;
        return newHash;
      }, this.populationLookupHash);   // initialized to {}

      this.pcfMax = Math.floor(this.puTotal * (1 - (this.government().pcf / 100)));

      // Step 2
      this.transactions().forEach((transaction) => this.setTransactionHash(transaction));

      // Step 3
      this.bodyData = signal(Object.values(this.populationLookupHash));
      console.log("this.transactionLookupHash", this.transactionLookupHash);
      this.session.iAmNotBusy();
    });
  };

  override ngOnInit (): void {
    super.ngOnInit();
  };

  processTransaction (transaction: Transaction) {
    let originalTransaction = this.transactionById[transaction._id];

    if (transaction._id && transaction.quantity === 0) {
      this.deleteTransaction(transaction);
    }
    else if ((originalTransaction?.quantity || 0) !== transaction.quantity) {
      this.saveTransaction(transaction);
    }
  };

  override processEditResult (result: { [key: string]: any; }) {
    let pcfTransaction = result['document'] as Transaction;
    let pcfaTransaction = result['pcfaTransaction'] as Transaction;
    this.processTransaction(pcfTransaction);
    this.processTransaction(pcfaTransaction);
  };

  createNewPcfTransactionData (pcfType: string, bodyId: string): Transaction {
    return {
      campaignId: this.campaign()._id,
      raceId: this.race()._id,
      type: this.transactionType,
      turn: this.session.turnEdit() || this.race().turnActivated,
      description: pcfType,
      size: this.race().techLevel,
      bodyId: bodyId,
      quantity: 0,
      amount: 0
    };
  };

  openTroopTransactionDialog (planetId: string) {
    let baseData = this.populationLookupHash[planetId];
    let pcfCost = this.race().techLevel + 3;
    let freePcf = this.transactionLookupHash[baseData.planetId]?.freePcf || 0;
    let freePcfa = this.transactionLookupHash[baseData.planetId]?.freePcfa || 0;
    let maxPcf = baseData['pu'] - freePcf - freePcfa;

    let pcfTransaction = this.transactionById[this.transactionLookupHash[baseData.planetId].PCFid];
    if (!pcfTransaction) {
      pcfTransaction = this.createNewPcfTransactionData('PCF', planetId);
    }

    let pcfaTransaction = this.transactionById[this.transactionLookupHash[baseData.planetId].PCFaid];
    if (!pcfaTransaction) {
      pcfaTransaction = this.createNewPcfTransactionData('PCFa', planetId);
    }

    let config = this.buildDataDialogConfig("Troop Transactions", pcfTransaction);
    config.data = Object.assign(config.data, {
      pcfaTransaction: pcfaTransaction,
      pcfCost: pcfCost,
      freePcf: freePcf,
      freePcfa: freePcfa,
      maxPcf: maxPcf,
    });

    this.openTransactionDialog(undefined, undefined, this.componentType, config);
  };

  deleteTransactions (planetId: string) {
    let baseData = this.transactionLookupHash[planetId];
    if (baseData) {
      let pcfTransaction = this.transactionById[baseData.PCFid];
      let pcfaTransaction = this.transactionById[baseData.PCFaid];

      if (pcfTransaction) {
        this.deleteTransaction(pcfTransaction);
      }
      if (pcfaTransaction) {
        this.deleteTransaction(pcfaTransaction);
      }
    }
  };

  override processDeleteResult (result) {
    let planetId = result['planetId'] as string;
    this.deleteTransactions(planetId);
  };

  deleteTroopTransactionDialog (planetId: string) {
    let config = this.buildDataDialogConfig("Troop Transactions", {});
    config.data['planetId'] = planetId;
    this.openDeleteTransactionDialog("Troop Transactions", {}, undefined, config);
  };
};
